<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        sort="id"
        :descending="true"
        title="Queued Jobs"
        icon="mdi-comment-processing"
        stateendpoint="queuedJobLogs.queuedJobLogs"
        :actions="actions"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";

export default {
  name: 'QueuedJobLogs',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Queued Job Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Queue Name',
          value: 'queue'
        },
        {
          text: 'Total Queued',
          align: 'right',
          value: 'totalQueued'
        }
      ],
      columns: [
        {value: 'logs'},
      ],
      actions: {
        load: 'loadQueuedJobLogs'
      }
    }
  }
}
</script>
